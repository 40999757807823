<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>
            <h5 v-if="updated">Modifica Necrologio</h5>
            <h5 v-else>Aggiungi Necrologio</h5>
          </strong>
        </CCardHeader>
        <br />

        <CCardHeader>
          <strong>Foto Necrologio</strong>
        </CCardHeader>

        <CCard>
          <CCardBody class="custom-card-body">
            <CCol sm="12">
              <br />
              <h6>Necrologio con foto?</h6>
              <CSwitch
                v-if="foto_profilo === 'https://ucarecdn.com/e2b95dba-3046-496b-a4f1-a3919b6c1764/'"
                labelOn="Si"
                labelOff="No"
                color="primary"
                size="lg"
                @update:checked="(val) => fotoIsRequired(val)"
              />
              <CSwitch
                v-else
                checked
                labelOn="Si"
                labelOff="No"
                color="primary"
                size="lg"
                @update:checked="(val) => fotoIsRequired(val)"
              />
              <br />
              <br />
              <CRow v-if="foto_required">
                <CCol sm="6">
                  <vue-dropzone
                    :options="dropzoneOptions"
                    :useCustomSlot="true"
                    ref="myVueDropzone"
                    @vdropzone-success="filesAdded"
                  >
                    <div class="dropzone-custom-content">
                      <h3 class="dropzone-custom-title">Carica qui la foto del defunto</h3>
                      <div class="subtitle">Trascina o clicca per caricarle</div>
                    </div>
                  </vue-dropzone>
                  <br />
                  <br />
                </CCol>

                <CCol sm="6">
                  <div class="custom-elenco-foto" v-for="(item, index) in imgList">
                    <CCard class="card-custom-foto">
                      <CCardHeader>
                        <h5 class="text-anteprima-necrologio">Anteprima foto ritagliata</h5>
                      </CCardHeader>

                      <CCardBody @click="editPhoto(item, index)">
                        <!-- Mostro tutte le foto del gruppo di uploadcare -->
                        <CImg
                          :src="item.src"
                          class="custom-image-preview align-content-center"
                          @click="editPhoto(item, index)"
                        />
                      </CCardBody>

                      <CCardFooter>
                        <CButton
                          shape="square"
                          variant="ghost"
                          class="custom-button-delete"
                          v-on:click="editPhoto(item, index)"
                          type="submit"
                          size="lg"
                          color="warning"
                        >
                          <CIcon name="cil-pencil" />
                          Modifica
                        </CButton>

                        <!-- Cancellazione della immagine -->
                        <CButton
                          shape="square"
                          variant="ghost"
                          class="custom-button-delete"
                          @click="deletePhoto()"
                          type="submit"
                          size="lg"
                          color="danger"
                        >
                          <CIcon name="cil-x-circle" />
                          Elimina
                        </CButton>
                      </CCardFooter>
                    </CCard>
                  </div>
                </CCol>
              </CRow>
            </CCol>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Nome Necrologio</strong>
        </CCardHeader>
        <CCard class="customCard">
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <div class="nome_cognome_bold">
                  <CInput
                    v-model="nome"
                    label="Nome completo (Nome e Cognome) (*)"
                    placeholder="es: Mario Rossi"
                  />
                </div>
              </CCol>

              <CCol sm="6">
                <CInput
                  v-model="cognome_marito"
                  label="Cognome Marito e altro"
                  placeholder="es: Ved. Verdi"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Indirizzo Necrologio</strong>
        </CCardHeader>

        <CCard>
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <SingleComune
                  v-bind:selected="comuni"
                  v-on:getComune="setComune"
                  title="Comune residenza (*)"
                />
              </CCol>
              <CCol sm="6">
                <div class="nome_cognome_bold">
                  <h6>Indirizzo di residenza?</h6>

                  <CSwitch
                    v-if="flag_indirizzo_residenza === true"
                    checked
                    labelOn="Si"
                    labelOff="No"
                    color="primary"
                    size="lg"
                    @update:checked="(val) => indirizzoRequired(val)"
                  />

                  <CSwitch
                    v-if="flag_indirizzo_residenza === false"
                    labelOn="Si"
                    labelOff="No"
                    color="primary"
                    size="lg"
                    v-model="flag_indirizzo_residenza"
                    @update:checked="(val) => indirizzoRequired(val)"
                  />
                </div>
              </CCol>
            </CRow>

            <CRow v-if="flag_indirizzo_residenza === true">
              <CCol sm="12">
                <CCardHeader>
                  <strong>Indirizzo di residenza</strong>
                </CCardHeader>
                <CCard class="customCard">
                  <CCardBody>
                    <CRow>
                      <CCol sm="6">
                        <CInput v-model="indirizzo" />
                      </CCol>
                    </CRow>
                  </CCardBody>
                </CCard>
              </CCol>
            </CRow>

            <br />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Date Necrologio</strong>
        </CCardHeader>
        <CCard class="customCard">
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <h6 class="text">Data di Nascita</h6>
                <v-date-picker :value="data_nascita" mode="date" v-model="data_nascita" />
              </CCol>
              <CCol sm="6">
                <CInput v-model="luogo_nascita" label="Luogo di Nascita" placeholder="es: Londra" />
              </CCol>
            </CRow>

            <CRow>
              <CCol sm="6">
                <div class="nome_cognome_bold">
                  <h6 class="text nome_cognome_bold">Data di Morte (*)</h6>
                </div>
                <v-date-picker :value="data_morte" mode="date" v-model="data_morte" />
              </CCol>
              <CCol sm="6">
                <CInput v-model="luogo_morte" label="Luogo di Morte" placeholder="es: Roma" />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="12">
        <CCardHeader>
          <strong>Cimitero Necrologio</strong>
        </CCardHeader>
        <CCard class="customCard">
          <CCardBody>
            <CRow>
              <CCol sm="6">
                <div class="nome_cognome_bold">
                  <CInput v-model="cimitero" label="Cimitero (*)" placeholder="es: Roma" />
                </div>
              </CCol>
              <CCol sm="6">
                <CInput
                  v-model="posto_nel_cimitero"
                  label="Posto nel Cimitero"
                  placeholder="es: Ala nuova B23 settore C1"
                />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- PENSIERI DEL CUORE -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <span>
              <h5>Pensieri del cuore</h5>
            </span>
          </CCardHeader>

          <CCardBody>
            <div class="listPensieriCuore" v-for="(n, index) in number_pensieri">
              <CCardHeader>
                <CButton
                  v-on:click="removePensieroCuore(index)"
                  type="submit"
                  size="lg"
                  color="danger"
                >
                  <CIcon name="cil-trash" />
                  Rimuovi pensiero del cuore
                </CButton>

                <br />
                <br />
                <h5>Autore</h5>
                <CInput v-model="itemsPensieriCuore.data[index].autore" />
                <h5>Pensiero del cuore</h5>
                <vue-editor v-model="itemsPensieriCuore.data[index].pensiero"></vue-editor>

                <br />
              </CCardHeader>
              <br />
            </div>

            <CButton v-on:click="addPensieroCuore" type="submit" size="lg" color="info">
              Aggiungi Pensieri del cuore
            </CButton>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- FINE PENSIERI DEL CUORE -->

    <!-- PENSIERI DEL CUORE -->
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <span>
              <h5>Info pubblicazione</h5>
            </span>
          </CCardHeader>

          <CCardBody>
            <p class="text">Pubblicazione solo Monitor</p>
            <CSwitch
              label-on="Si"
              label-off="No"
              class="mr-1"
              color="primary"
              :checked.sync="pubblicazione_solo_totem"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <!-- FINE PENSIERI DEL CUORE -->

    <!--Salvataggio-->
    <CRow>
      <!-- Bottone salvataggio del necrologio -->
      <CButton v-if="updated" v-on:click="update" type="submit" size="lg" color="info">
        <CIcon name="cil-save" />
        Aggiorna
      </CButton>
      <!-- Fine bottone -->

      <!-- Bottone aggiornamento del necrologio -->
      <CButton v-else v-on:click="save" type="submit" size="lg" color="info">
        <CIcon name="cil-save" />
        Salva
      </CButton>
      <!-- Fine bottone -->
      <!-- Bottone aggiornamento del necrologio -->

      <CButton
        v-if="updated === false"
        v-on:click="saveAndRedirectManifesto"
        type="submit"
        class="button-save-and-redirect"
        size="lg"
        color="info"
      >
        <CIcon name="cil-save" />
        Salva e inserisci manifesto
      </CButton>
      <!-- Fine bottone -->

      <CButton
        v-if="updated"
        v-on:click="remove"
        type="submit"
        class="cancella-necrologio"
        size="lg"
        color="danger"
      >
        <CIcon name="cil-trash" />
        Elimina
      </CButton>

      <br />
      <br />
    </CRow>
    <!-- Fine Manifesti visibili -->

    <!-- VALIDAZIONE DEI DATI -->
    <CModal
      title="Errore nel campo dei dati"
      class="modal-error-save-necrologi"
      color="white"
      size="xl"
      fade
      :closeOnBackdrop="false"
      centered
      :show.sync="validationModal"
    >
      <template #footer>
        <CButton size="lg" @click="validationModal = false" color="info">Chiudi</CButton>
        <CButton size="lg" @click="validationModal = false" color="info">Ok</CButton>
      </template>
      <br />
      <h4 style="text-align: center">
        Devi inserire i dati con (*) prima di procedere con il salvataggio
      </h4>
      <br />
      <div class="custom-error-text">
        <div class="listaErrori" v-for="(n, index) in errorText">
          <h5>{{ index }} : {{ n }}</h5>
        </div>
      </div>
    </CModal>
    <!-- FINE VALIDAZIONE DEI DATI -->

    <CModal
      title="Gestione Foto"
      addContentClasses="modal-gestione-foto"
      color="white"
      size="xl"
      fade
      :closeOnBackdrop="false"
      centered
      :show.sync="uploadPhotoModal"
    >
      <template #footer>
        <div style="display: none"></div>
      </template>
      <cropper
        class="upload-example-cropper"
        :src="image"
        ref="cropper"
        :stencil-props="{ aspectRatio: this.aspectRatioVariable }"
      />
      <div class="upload-example"></div>

      <CButton
        class="custom-botton-save-and-end-edit-modal"
        v-on:click="crop"
        type="submit"
        shape="square"
        size="lg"
        color="info"
      >
        Clicca per ritagliare e salvare
      </CButton>
    </CModal>

    <!-- Modale che mostra l'anteprima dell'immagine -->
    <CModal
      class="modal-show-image"
      color="white"
      size="xl"
      fade
      :closeOnBackdrop="false"
      centered
      :show.sync="viewPhotoModal"
    >
      <img :src="image" width="100%" height="auto" />
    </CModal>
  </div>
</template>

<script>
import GoogleMapsAutocomplete from '@/views/funer24/components/GoogleMapsAutocomplete';
import SingleCap from '@/views/funer24/components/SingleCap';
import SingleComune from '@/views/funer24/components/SingleComune';
import UploadClient from '@uploadcare/upload-client';
import moment from 'moment';
import { DatePicker } from 'v-calendar';
import { Cropper, Preview } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import { VueEditor } from 'vue2-editor';
import 'vue2-timepicker/dist/VueTimepicker.css';

export default {
  name: 'AggiungiNecrologio',
  components: {
    SingleCap,
    SingleComune,
    VSwatches,
    'v-date-picker': DatePicker,
    VueEditor,
    vueDropzone: vue2Dropzone,
    GoogleMapsAutocomplete,
    Cropper,
    Preview,
  },
  data: function () {
    return {
      idNecrologio: null,
      updated: false,
      number_pensieri: 0,
      id: null,
      foto_profilo: null,
      image: null,
      nome: null,
      indirizzo: null,
      comuni: null,
      cap: null,
      flag_indirizzo_residenza: true,
      data_nascita: null,
      data_morte: null,
      luogo_nascita: null,
      luogo_morte: null,
      cognome_marito: null,
      cimitero: null,
      posto_nel_cimitero: null,
      timezone: '',
      stato_pubblicazione: '',
      autore: '',
      testo_pensiero: '',
      uploadPhotoModal: false,
      showPhotoModal: false,
      viewPhotoModal: false,
      pubblicazione_solo_totem: false,
      aspectRatioVariable: 1,
      foto_required: true,
      uploadPhotoShow: true,
      showPhotoList: true,
      center: '',
      result: {
        coordinates: null,
        image: null,
      },
      errorText: [],
      validationModal: false,
      imgList: [],
      geolocation: null,
      itemsPensieriCuore: {
        data: [],
      },
      data: [],
      options: [],
      itemsTotem: [],
      clientUploadCare: new UploadClient({ publicKey: '83237effd4da9f06ef10' }),
      uploadCarePublicKey: '83237effd4da9f06ef10',
      coordinates: {
        width: 0,
        height: 0,
        left: 0,
        top: 0,
      },

      dropzoneOptions: {
        url: 'https://api.funer24.com/post',
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        resizeQuality: 0.1,
        chunking: true,
        resizeMethod: 'crop',
        resizeWidth: 500,
        resizeHeight: 500,
        forceChunking: true,
        retryChunks: true,
        retryChunksLimit: 5,
        maxFiles: 1,
        maxFilesize: 2,
      },
    };
  },

  methods: {
    setComune(data) {
      this.comuni = data;
    },

    removePensieroCuore(index) {
      this.itemsPensieriCuore.data.splice(index, 1);
      this.number_pensieri--;
    },

    addPensieroCuore() {
      this.itemsPensieriCuore.data.push({ id: this.number_pensieri, pensiero: '', autore: '' });
      this.number_pensieri++;
    },

    async remove() {
      if (this.idNecrologio != null) {
        try {
          await axios.delete('/dashboard/api/v0/necrologi/delete/' + this.idNecrologio, {});
        } catch (error) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    },

    fotoIsRequired(item) {
      this.foto_required = item;
    },

    indirizzoRequired(item) {
      this.flag_indirizzo_residenza = item;
    },

    setDefaultPhoto() {
      if (!this.foto_required)
        this.foto_profilo = 'https://ucarecdn.com/e2b95dba-3046-496b-a4f1-a3919b6c1764/';
    },

    async update() {
      let comune = null;
      if (this.comuni !== null) {
        if (this.comuni.ref_id !== undefined) {
          comune = parseInt(this.comuni.ref_id);
        } else {
          comune = parseInt(this.comuni.id);
        }
      }

      if (!this.validation()) {
        this.validationModal = true;
      }

      if (this.idNecrologio !== null && this.validation()) {
        this.setDefaultPhoto();

        try {
          const response = await axios.patch(
            '/dashboard/api/v0/necrologi/update/' + this.$route.params.idNecrologio,
            {
              foto_profilo: this.foto_profilo,
              nome: this.nome,
              indirizzo: this.indirizzo,
              comuni: comune,
              data_nascita: this.format_date_frontend_to_backend(this.data_nascita),
              data_morte: this.format_date_frontend_to_backend(this.data_morte),
              luogo_morte: this.luogo_morte,
              luogo_nascita: this.luogo_nascita,
              cognome_marito: this.cognome_marito,
              address: this.indirizzo,
              geolocation: this.geolocation,
              cimitero: this.cimitero,
              pubblicazione_solo_totem: this.pubblicazione_solo_totem,
              flag_indirizzo_residenza: this.flag_indirizzo_residenza,
              reset_foto: true,
              posto_nel_cimitero: this.posto_nel_cimitero,
            }
          );

          if (response.status === 200) {
            this.$router.push({
              name: 'listaNecrologi',
            });
          }
        } catch (error) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    },

    async crop() {
      const { coordinates, canvas } = this.$refs.cropper.getResult();
      this.coordinates = coordinates;
      this.image = canvas.toDataURL('image/jpeg', 0.6);
      this.imgList = [];
      await this.photoUploader();
      this.showPhotoModal = false;
      this.uploadPhotoModal = false;
    },

    setAddress(data) {
      this.indirizzo = data.address;
      this.geolocation = data.lat + ',' + data.lng;
    },

    editImage(item) {
      this.showPhotoModal = true;
      this.foto_profilo = item.src;
      this.image = item.src;
    },

    fillImgList(obj) {
      const baseLink = 'https://ucarecdn.com/';
      this.foto_profilo = baseLink + obj.uuid + '/';
      this.imgList.push({ uuid: obj.uuid, src: baseLink + obj.uuid + '/' });
    },

    /**
     * Ritorna la lista delle foto dato un uuid di un gruppo
     * @param uuid
     */
    async getListImage(uuid) {
      try {
        const response = await axios.get(
          'https://upload.uploadcare.com/info/?pub_key=' +
            this.uploadCarePublicKey +
            '&file_id=' +
            uuid
        );

        if (response.status === 200) {
          this.imgList = [];
          this.foto_profilo = uuid;
          this.fillImgList(response.data);
        }
      } catch (error) {
        console.log(error);
      }
    },

    async filesAdded(file, response) {
      try {
        this.imgList = [];
        const compressedImageDataURL = await this.compressImage(file.dataURL, 0.7);
        const fileToUpload = this.dataURLtoFile(compressedImageDataURL, 'image');
        const obj = await this.clientUploadCare.uploadFile(fileToUpload);
        this.fillImgList(obj);
        this.$refs.myVueDropzone.removeFile(file);
      } catch (error) {
        console.error(error);
      }
    },

    compressImage(dataURL, quality = 0.7) {
      return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');

          let width = img.width;
          let height = img.height;
          const maxDimension = 1200; // Dimensione massima per larghezza o altezza

          if (width > maxDimension || height > maxDimension) {
            if (width > height) {
              height = height * (maxDimension / width);
              width = maxDimension;
            } else {
              width = width * (maxDimension / height);
              height = maxDimension;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          const compressedDataURL = canvas.toDataURL('image/jpeg', quality);
          resolve(compressedDataURL);
        };
        img.src = dataURL;
      });
    },

    loadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    // https://uploadcare.com/docs/uploads/file-uploader/
    async photoUploader() {
      try {
        console.log('Dimensione originale:', Math.round(this.image.length / 1024), 'KB');

        const compressedImageDataURL = await this.compressImage(this.image, 0.7);

        console.log(
          'Dimensione compressa:',
          Math.round(compressedImageDataURL.length / 1024),
          'KB'
        );

        const fileToUpload = this.dataURLtoFile(compressedImageDataURL, 'image');
        const obj = await this.clientUploadCare.uploadFile(fileToUpload);
        this.fillImgList(obj);
        return obj;
      } catch (error) {
        console.error("Errore durante l'upload della foto:", error);
      }
    },

    deletePhoto() {
      this.imgList = [];
      this.foto_profilo = null;
      this.image = null;
    },

    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    editPhoto(item, index) {
      this.foto_profilo = item.src;
      this.uploadPhotoModal = true;
      this.image = item.src;
    },

    showViewPhoto(item) {
      this.foto_profilo = item.src;
      this.image = item.src;
      this.viewPhotoModal = true;
    },

    validate(field) {
      if (field !== null) {
        return true;
      }
      return false;
    },

    validation() {
      this.errorText = [];

      if (this.foto_required) {
        if (!this.validate(this.foto_profilo)) {
          this.errorText.push('La Foto del necrologio non è stata inserita');
        }
      }

      if (!this.validate(this.nome)) {
        this.errorText.push('Il campo Nome Completo non è stato inserito');
      }

      if (this.nome !== null) {
        if (this.nome.length > 0) {
          if (this.nome === this.nome.toUpperCase()) {
            this.errorText.push(
              'Il campo nome completo deve essere scritto come segue: es: Mario Rossi e non MARIO ROSSI'
            );
          }
        }
      }

      if (this.cognome_marito !== null) {
        if (this.cognome_marito.length > 0) {
          if (this.cognome_marito === this.cognome_marito.toUpperCase()) {
            this.errorText.push(
              'Il campo Cognome Marito deve essere scritto come segue: es: Mario Rossi e non MARIO ROSSI'
            );
          }
        }
      }

      if (this.luogo_nascita !== null) {
        if (this.luogo_nascita.length > 0) {
          if (this.luogo_nascita === this.luogo_nascita.toUpperCase()) {
            this.errorText.push(
              'Il campo Luogo di Nascita deve essere scritto come segue: es: Roma e non ROMA'
            );
          }
        }
      }

      if (this.luogo_morte !== null) {
        if (this.luogo_morte.length > 0) {
          if (this.luogo_morte === this.luogo_morte.toUpperCase()) {
            this.errorText.push(
              'Il campo Luogo di Morte deve essere scritto come segue: es: Roma e non ROMA'
            );
          }
        }
      }

      if (this.cimitero !== null) {
        if (this.cimitero.length > 0) {
          if (this.cimitero === this.cimitero.toUpperCase()) {
            this.errorText.push(
              'Il campo Cimitero deve essere scritto come segue: es: Sezione A1 e non SEZIONE A1'
            );
          }
        }
      }

      if (this.flag_indirizzo_residenza) {
        if (!this.validate(this.indirizzo) && !this.validate(this.geolocation)) {
          this.errorText.push('Il campo indirizzo non è stato inserito');
        }
      }

      if (!this.validate(this.comuni)) {
        this.errorText.push('Il campo Comune di Residenza non è stato inserito');
      }

      if (!this.validate(this.data_morte)) {
        this.errorText.push('Il campo Data di morte non è stato inserito');
      }
      if (!this.validate(this.cimitero) || this.cimitero.length === 0) {
        this.errorText.push('Il campo Cimitero non è stato inserito');
      }

      if (this.errorText.length === 0) {
        return true;
      }
      return false;
    },

    async saveAndRedirectManifesto() {
      if (!this.validation()) {
        this.validationModal = true;
      }

      this.setDefaultPhoto();

      if (this.validation()) {
        let comune = null;
        if (this.comuni !== null) {
          if (this.comuni.ref_id !== undefined) {
            comune = parseInt(this.comuni.ref_id);
          } else {
            comune = parseInt(this.comuni.id);
          }
        }

        try {
          const necrologioData = {
            foto_profilo: this.foto_profilo,
            nome: this.nome,
            indirizzo: this.indirizzo,
            comuni: comune,
            data_nascita: this.format_date_frontend_to_backend(this.data_nascita),
            data_morte: this.format_date_frontend_to_backend(this.data_morte),
            luogo_morte: this.luogo_morte,
            luogo_nascita: this.luogo_nascita,
            address: this.indirizzo,
            stato_pubblicazione: 'PUBBLICATO',
            pubblicazione_solo_totem: this.pubblicazione_solo_totem,
            cognome_marito: this.cognome_marito,
            geolocation: this.geolocation,
            flag_indirizzo_residenza: this.flag_indirizzo_residenza,
            cimitero: this.cimitero,
            posto_nel_cimitero: this.posto_nel_cimitero,
          };

          const response = await axios.post('/dashboard/api/v0/necrologi/create', necrologioData);

          if (this.itemsPensieriCuore.data.length === 0) {
            this.$router.push({
              name: 'manifesto',
            });
          }

          if (response.status === 201) {
            //Salvo i pensieri del cuore
            let necrologioId = response.data.id;
            for (let index in this.itemsPensieriCuore.data) {
              try {
                const pensieroResponse = await axios.post(
                  '/dashboard/api/v0/necrologi/pensiericuore/create',
                  {
                    autore: this.itemsPensieriCuore.data[index].autore,
                    pensiero_dal_cuore: this.itemsPensieriCuore.data[index].pensiero,
                    necrologi: necrologioId,
                  }
                );

                if (pensieroResponse.status === 201) {
                  this.updated = true;
                  this.$router.push({
                    name: 'manifesto',
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        } catch (error) {
          // Request made and server responded
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    },

    // Salvataggio del necrologio e con tutti i pensieri del cuore
    async save() {
      if (!this.validation()) {
        this.validationModal = true;
      }

      let comune = null;
      if (this.comuni !== null) {
        if (this.comuni.ref_id !== undefined) {
          comune = parseInt(this.comuni.ref_id);
        } else {
          comune = parseInt(this.comuni.id);
        }
      }

      this.setDefaultPhoto();

      if (this.validation()) {
        try {
          const response = await axios.post('/dashboard/api/v0/necrologi/create', {
            foto_profilo: this.foto_profilo,
            nome: this.nome,
            indirizzo: this.indirizzo,
            comuni: comune,
            data_nascita: this.format_date_frontend_to_backend(this.data_nascita),
            data_morte: this.format_date_frontend_to_backend(this.data_morte),
            luogo_morte: this.luogo_morte,
            luogo_nascita: this.luogo_nascita,
            address: this.indirizzo,
            pubblicazione_solo_totem: this.pubblicazione_solo_totem,
            geolocation: this.geolocation,
            flag_indirizzo_residenza: this.flag_indirizzo_residenza,
            stato_pubblicazione: 'PUBBLICATO',
            cognome_marito: this.cognome_marito,
            cimitero: this.cimitero,
            posto_nel_cimitero: this.posto_nel_cimitero,
            reset_foto: true,
          });

          if (this.itemsPensieriCuore.data.length === 0) {
            this.$router.push({
              name: 'listaNecrologi',
            });
          }

          // Creazione pensieri del cuore
          if (response.status === 201) {
            //Salvo i pensieri del cuore
            let necrologioId = response.data.id;
            for (let index in this.itemsPensieriCuore.data) {
              try {
                const pensieroResponse = await axios.post(
                  '/dashboard/api/v0/necrologi/pensiericuore/create',
                  {
                    autore: this.itemsPensieriCuore.data[index].autore,
                    pensiero_dal_cuore: this.itemsPensieriCuore.data[index].pensiero,
                    necrologi: necrologioId,
                  }
                );

                if (pensieroResponse.status === 201) {
                  this.updated = true;
                  this.$router.push({
                    name: 'listaNecrologi',
                  });
                }
              } catch (error) {
                console.log(error);
              }
            }
          }
        } catch (error) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      }
    },

    format_date_frontend_to_backend(value) {
      if (value) {
        return moment(String(value)).format('YYYY-MM-DD');
      }
    },

    format_date_backend_to_frontend(value) {
      if (value) {
        return moment(String(value)).format('L');
      }
    },
  },

  async mounted() {
    this.idNecrologio = this.$route.params.idNecrologio;
    if (!this.idNecrologio) return;
    this.updated = true;

    try {
      const [necrologioResponse, pensieriResponse] = await Promise.all([
        axios.get('/dashboard/api/v0/necrologi/get/' + this.idNecrologio),
        axios.get(
          '/dashboard/api/v0/necrologi/pensiericuore/list?necrologio_id=' + this.idNecrologio
        ),
      ]);

      if (necrologioResponse.status === 200) {
        const data = necrologioResponse.data;
        this.foto_profilo = data.foto_profilo.replace('https://ucarecdn.com/', '').replace('/', '');
        await this.getListImage(this.foto_profilo);

        this.nome = data.nome;
        this.indirizzo = data.indirizzo;
        this.comuni = data.comuni;
        this.cognome_marito = data.cognome_marito;
        this.data_nascita = new Date(data.data_nascita);
        this.data_morte = new Date(data.data_morte);
        this.luogo_nascita = data.luogo_nascita;
        this.luogo_morte = data.luogo_morte;
        this.pubblicazione_solo_totem = data.pubblicazione_solo_totem;
        this.geolocation = data.geolocation;
        this.address = data.address;
        this.cimitero = data.cimitero;
        this.flag_indirizzo_residenza = data.flag_indirizzo_residenza;
        this.posto_nel_cimitero = data.posto_nel_cimitero;
        this.stato_pubblicazione = data.stato_pubblicazione;

        let coordinate = data.geolocation.split(',').map(Number);
        this.center = { lat: coordinate[0], lng: coordinate[1] };
      }

      if (pensieriResponse.data.results && pensieriResponse.data.results.length > 0) {
        for (let item in pensieriResponse.data.results) {
          this.itemsPensieriCuore.data.push({
            id: this.number_pensieri,
            pensiero: pensieriResponse.data.results[item].pensiero_dal_cuore,
            autore: pensieriResponse.data.results[item].autore,
          });
          this.number_pensieri++;
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

<style>
button.cancella-necrologio {
  margin-left: 10px;
}

.modal-body {
  font-size: 16px;
}

.vue-advanced-cropper__background,
.vue-advanced-cropper__foreground {
  background: #fff !important;
}

.dropzone-custom-content {
  padding: 80px;
}

h3.dropzone-custom-title {
  font-weight: 900;
  font-size: 3vw;
}

div.custom-elenco-foto.col-md-4 {
  display: inline-flex;
}

button.btn.custom-button-delete {
  width: 50%;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-pill {
  width: 100%;
  height: 75px;
  font-weight: 700;
}

button.btn.pressed-reps.btn-info.btn-lg.btn-pill {
  background-color: #f9b115;
}

img.custom-image-preview.align-content-center {
  border-radius: 50%;
  width: 124px;
  height: 124px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-top: 43px;
  margin-bottom: 24px;
}

.text-anteprima-necrologio {
  text-align: center;
}

.upload-example-cropper {
  border: solid 1px #eee;
  height: 600px;
  width: auto;
  z-index: 99999999;
}

.btn-info:hover {
  color: #fff;
  background-color: #ffbf01 !important;
  border-color: #ffbf01;
}

button.btn.btn-info.btn-md {
  background-color: #670196 !important;
}

.modal-gestione-foto.modal-content > footer {
  display: none;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-square {
  margin-top: 24px;
  margin-left: 400px;
}

.card.card-custom-foto {
  height: 366px;
}

div#dropzone {
  height: 366px;
}

.nome_cognome_bold {
  font-weight: 700;
}

button.btn.custom-botton-save-and-end-edit-modal.btn-info.btn-lg.btn-square {
  width: 100%;
  margin: 0;
  margin-top: 10px;
}

button.btn.button-save-and-redirect.btn-info.btn-lg {
  margin-left: 20px;
}

.card-body.custom-card-body {
  padding: 4px;
}

.vue-handler-wrapper__draggable {
  background-color: #670096 !important;
}
</style>
